import React from 'react'
import { setEventProperties, setPageProperties, isBrowser  } from '../../utils/zeotap';
import { SiteContext, ContextProviderComponent } from '../context/mainContext'
import { DENOMINATION } from '../../providers/inventoryProvider'
import { FaTimes, FaLongArrowAltRight } from 'react-icons/fa'
import CartLink from '../components/CartLink'
import LogLink from '../components/LogLink'
import { slugify, titleIfy } from '../../utils/helpers'
import Image from '../components/Image'
import { omit } from 'ramda'
import { nanoid } from 'nanoid';
import ConditionalLink from '../components/ConditionalLink';
import { getSiteConfigField } from '../../utils/helpers';

const Cart = ({ context }) => {
  const { numberOfItemsInCart, cart, removeFromCart, total } = context
  const cartEmpty = numberOfItemsInCart === Number(0)
  return (
    <>
      <CartLink />
      <LogLink />
      <div className="flex flex-col items-center pb-10">
        <div className="
          flex flex-col w-full
          c_large:w-c_large
        ">
          <div className="pt-10 pb-8">
            <h1 className="text-5xl font-light">Your Cart</h1>
          </div>

          {
            cartEmpty ? (
              <h3>No items in cart.</h3>
            ) : (
              <div className="flex flex-col">
                <div className="">
                  {
                    cart.map((item, i) => {
                      return (
                        <div className="border-b py-10" key={item.id+i}>
                          <div className="flex items-center">
                            <ConditionalLink redirectUrl={`/${slugify(item.name)}`} onClick={()=>setEventProperties('viewProduct',{productID:item.id})} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
                              <Image className="w-32 m-0 showcase" src={item.image} alt={item.name} />
                            </ConditionalLink>
                            <ConditionalLink redirectUrl={`/${slugify(item.name)}`} onClick={()=>setEventProperties('viewProduct',{productID:item.id})} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
                              <p className="m-0 pl-10 text-gray-600 text-sm">
                                {item.name}
                              </p>
                            </ConditionalLink>
                            <div className="flex flex-1 justify-end">
                              <p className="m-0 pl-10 text-gray-900 tracking-tighter font-semibold">
                                {(item.price.includes('£') ? '': DENOMINATION) + item.price}
                              </p>
                            </div>
                            <div role="button" onClick={() => {
                              const itemAttributes = omit(['id','name', 'price', 'catalog', 'brand', 'categories', 'currentInventory', 'image', 'description'], item)
                              const id = nanoid();
                              // window.fbq('trackCustom', 'removeFromCart', {
                              //     ...itemAttributes,
                              //     event_id: id,
                              //     eventID: id,
                              //     productID:item.id,
                              //     product_name:item.name,
                              //     product_price:+item.price,
                              //     product_price_currency:DENOMINATION,
                              //     product_quantity:+item.currentInventory,
                              //     shoppingcart_value:+total,
                              //     shoppingcart_value_currency:DENOMINATION,
                              //   });
                              setEventProperties('removeFromCart',{
                                ...itemAttributes,
                                id,
                                productID:item.id,
                                product_name:item.name,
                                product_price:+item.price,
                                product_price_currency:DENOMINATION,
                                product_quantity:+item.currentInventory,
                                shoppingcart_value:+total,
                                shoppingcart_value_currency:DENOMINATION,
                                });removeFromCart(item);}} className="m-0 ml-10 text-gray-900 text-s cursor-pointer">
                              <FaTimes />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>  
            </div>
            )
          }
          <div className="flex flex-1 justify-end py-8">
            <p className="text-sm pr-10">Total</p>
            <p className="font-semibold tracking-tighter">{DENOMINATION + total}</p>
          </div>
          {!cartEmpty && (
            <ConditionalLink redirectUrl="/checkout/" className="flex flex-1 justify-end" onClick={()=>{
                // window.fbq('track', 'InitiateCheckout');
                setEventProperties(`initiateCheckout`);
            }} 
            isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }
            >
              <div className="cursor-pointer flex">
                <p className="text-gray-600 text-sm mr-2">Proceed to check out</p>
                <FaLongArrowAltRight className="text-gray-600 mt-1" />
              </div>
            </ConditionalLink>
          )}
        </div>
      </div>
    </>
  )
}

function CartWithContext(props) {
  setPageProperties({page_category:titleIfy(slugify(props.path)),page_name:titleIfy(slugify(props.path)),page_domain:isBrowser() && window.location.hostname})
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {
          context => <Cart {...props} context={context} />
        }
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}


export default CartWithContext